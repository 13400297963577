import React from 'react';
import JumboLayoutProvider from "@jumbo/components/JumboLayout/JumboLayoutProvider";
import JumboContentLayoutProvider from "@jumbo/components/JumboContentLayout/JumboContentLayoutProvider";
import useJumboApp from "@jumbo/hooks/useJumboApp";
import {LAYOUTS} from "./utils/constants/layouts";
import {config} from "./config/main";
import useJumboAuth from "@jumbo/hooks/useJumboAuth";
import LogoLoader from "./shared/LogoLoader";


const AppLayout = (props) => {
    const {activeLayout} = useJumboApp();
    const {isLoading} = useJumboAuth();

    if (!activeLayout) {
        throw Error("AppLayout > No activeLayout is set.");
    }

    const LayoutComponent = React.useMemo(() => {
        const layoutIndex = LAYOUTS.findIndex(layout => layout.name === activeLayout);

        if (layoutIndex >= 0) {
            return LAYOUTS[layoutIndex].component;
        }

        throw Error("No activeLayout is set yet.");
    }, [activeLayout]);

    return (
        <JumboLayoutProvider>
            {
                isLoading
                    ?
                    <div className="loader">
                         <svg
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 28 30.8"
    style={{}}
    xmlSpace="preserve"
  >
    <style type="text/css">
      {
        "\n\t.st0{fill:none;stroke:#0E64B5;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:10;}\n"
      }
    </style>
    <path
      className="st0 MyhpcXpq_0"
      d="M17,24.1l4.3-2.5l3.1-1.8c1.5-0.9,2.5-2.5,2.5-4.3c0-0.6-0.1-1.2-0.3-1.8c-0.4-1-1.1-1.9-2.1-2.5l-3.5-2 C20.7,9.1,20.5,9,20.3,9c0,0-0.1,0-0.1,0c0,0,0,0.1,0,0.1c-0.1,1.4-0.6,2.6-1.7,3.6c-0.4,0.4-0.7,0.7-1,0.8l2.6,4.1h0 c0.1,0.2,0.1,0.4,0.1,0.9c0,0.7-0.2,1.2-0.7,1.7C19,20.8,18.4,21,17.7,21c-0.7,0-1.3-0.2-1.8-0.7c-0.2-0.2-0.3-0.3-0.3-0.4L12,14.5 h-0.6v4.2c0,0.7-0.2,1.2-0.7,1.7c-0.1,0.1-0.2,0.2-0.3,0.2C10,20.9,9.5,21,8.9,21c-0.7,0-1.3-0.2-1.8-0.7c-0.5-0.5-0.7-1-0.7-1.7 V5.5c0-0.7,0.2-1.2,0.7-1.7c0.5-0.5,1.1-0.7,1.8-0.7h0.8C9.9,3,9.9,3,9.9,2.9c0-0.1,0-0.2-0.1-0.2L9.4,2.4c-1.5-0.9-3.4-0.9-5,0 C2.9,3.3,1.9,5,1.9,6.7v17.5c0,1.8,1,3.4,2.5,4.3c1.5,0.9,3.4,0.9,5,0l3.4-2l3.8-2.2L17,24.1"
    />
    <path
      className="st0 MyhpcXpq_1"
      d="M13,10.9c0.8,0,1.4-0.2,1.8-0.5s0.6-0.8,0.6-1.4c0-1.1-0.9-1.7-2.6-1.7h-1.5v2C11.4,10.1,12.1,10.9,13,10.9z"
    />
    <style data-made-with="vivus-instant">
      {
        ".MyhpcXpq_0{stroke-dasharray:127 129;stroke-dashoffset:128;animation:MyhpcXpq_draw_0 1700ms ease-in 0ms infinite,MyhpcXpq_fade 1700ms linear 0ms infinite;}.MyhpcXpq_1{stroke-dasharray:14 16;stroke-dashoffset:15;animation:MyhpcXpq_draw_1 1700ms ease-in 0ms infinite,MyhpcXpq_fade 1700ms linear 0ms infinite;}@keyframes MyhpcXpq_draw{100%{stroke-dashoffset:0;}}@keyframes MyhpcXpq_fade{0%{stroke-opacity:1;}82.35294117647058%{stroke-opacity:1;}100%{stroke-opacity:0;}}@keyframes MyhpcXpq_draw_0{0%{stroke-dashoffset: 128}64.70588235294117%{ stroke-dashoffset: 0;}100%{ stroke-dashoffset: 0;}}@keyframes MyhpcXpq_draw_1{0%{stroke-dashoffset: 15}64.70588235294117%{ stroke-dashoffset: 0;}100%{ stroke-dashoffset: 0;}}"
      }
    </style>
  </svg>
                    </div>
                    :
                    <LayoutComponent>
                        <JumboContentLayoutProvider
                            layoutOptions={config.defaultContentLayout}
                        >
                            {props.children}
                        </JumboContentLayoutProvider>
                    </LayoutComponent>
            }

        </JumboLayoutProvider>
    );
};

export default AppLayout;
