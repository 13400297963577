import React, { useState } from "react";
import { TextField, Stack, Autocomplete, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import axios from "../../services/config";
import { useSnackbar } from "notistack";
import * as Yup from "yup";
import JumboTextField from "@jumbo/components/JumboFormik/JumboTextField";

const driveViewers = [
  { title: "Website" },
  { title: "Phone Number" },
  { title: "Store" },
];

const validationSchema = Yup.object().shape({
  whatDoesYourBusinessDo: Yup.string().required("Required"),
  whatDoesYourBusinessOffer: Yup.string().required("Required"),
  whatAreYouSelling: Yup.string().required("Required"),
  cutThruPoints: Yup.string().required("Required"),
  slogansCatchphrases: Yup.string().required("Required"),
  mandatories: Yup.string().required("Required"),
  clientBusinessName: Yup.string().required("Required"),
  clientBusinessPhone: Yup.string().required("Required"),
  clientBusinessWebsite: Yup.string().required("Required"),
  clientContactName: Yup.string().required("Required"),
  clientContactPhone: Yup.string().required("Required"),
  clientContactEmail: Yup.string().email("Invalid email").required("Required"),
});

const Brief = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isSending, setIsSending] = useState(false);

  const onBriefSubmit = async (e, { resetForm, setSubmitting }) => {
    console.log("event", e);
    setIsSending(true);

    axios
      .post("/send", {
        toEmails: ["sales@recommercials.com.au"],
        subject: `New Client Brief - ${e.clientBusinessName}`,
        message: convert(e),
      })
      .then((response) => {
        console.log("server responded", response);
        enqueueSnackbar("Submission successful!", { variant: "success" });
        setIsSending(false);
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(
          "Error submitting! Please send all details to sales@recommercials.com.au if this persists.",
          { variant: "error" }
        );
        setIsSending(false);
      });
    console.log(convert(e));
  };

  const convert = (obj) =>
    Object.entries(obj)
      .map(([key, value]) =>
        value === Object(value)
          ? Array.isArray(value)
            ? `${key}[${convert(value)}]`
            : `${key}{${convert(value)}};`
          : `${capitalizeFirstLetter(key)
              .replace(/([A-Z])/g, " $1")
              .trim()}: \n${value}`
      )
      .join("\n\n");

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  return (
    <div>
      <Formik
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={validationSchema}
        onSubmit={onBriefSubmit}
        initialValues={{
          whatDoesYourBusinessDo: "",
          whatDoesYourBusinessOffer: "",
          whatAreYouSelling: "",
          cutThruPoints: "",
          slogansCatchphrases: "",
          mandatories: "",
          voiceoverPreferences: "",
          clientBusinessName: "",
          clientBusinessPhone: "",
          clientBusinessWebsite: "",
          clientContactPhone: "",
          clientContactName: "",
          clientContactEmail: "",
        }}
      >
        {({ isSubmitting, handleChange, handleSubmit }) => (
          <Form
            onSubmit={handleSubmit}
            style={{ textAlign: "left" }}
            noValidate
            autoComplete="off"
          >
            <Stack spacing={2} sx={{}}>
              <h2>Commercial Details</h2>

              <JumboTextField
                multiline
                minRows={2}
                name="whatDoesYourBusinessDo"
                label="What does your business do?"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                multiline
                minRows={2}
                name="whatDoesYourBusinessOffer"
                onChange={handleChange}
                label="What does your business offer over other competitors?"
                variant="outlined"
              />
              <JumboTextField
                multiline
                minRows={2}
                name="whatAreYouSelling"
                label="What are you selling in this promotion?"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                multiline
                minRows={3}
                name="cutThruPoints"
                label="What are three main cut through points of your business or product?"
                onChange={handleChange}
                variant="outlined"
              />

              <JumboTextField
                multiline
                minRows={3}
                name="slogansCatchphrases"
                label="Do you have any slogans/catch lines that need to appear in the TVC?"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                multiline
                minRows={3}
                name="mandatories"
                label="What mandatories must be included?"
                onChange={handleChange}
                variant="outlined"
              />
              <Autocomplete
                name="driveViewersAutoComplete"
                freeSolo
                fullWidth
                options={driveViewers.map((option) => option.title)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    name="driveViewers"
                    onChange={handleChange}
                    label="Where do you want to drive viewers?"
                  />
                )}
              />
              <JumboTextField
                multiline
                minRows={2}
                name="voiceoverPreferences"
                label="Voice over or music preference if any? (Male/ Female, Young/ Old)"
                onChange={handleChange}
                variant="outlined"
              />
              <Typography variant="h2">Client Details</Typography>
              <JumboTextField
                name="clientBusinessName"
                label="Business Name"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                name="clientBusinessPhone"
                label="Business Phone"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                name="clientBusinessWebsite"
                label="Business Website"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                name="clientContactName"
                label="Contact Name"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                name="clientContactPhone"
                label="Contact Phone"
                onChange={handleChange}
                variant="outlined"
              />
              <JumboTextField
                name="clientContactEmail"
                label="Contact Email"
                onChange={handleChange}
                variant="outlined"
              />
              <LoadingButton
                fullWidth
                type="submit"
                variant="contained"
                size="large"
                sx={{ mb: 3 }}
                loading={isSending}
              >
                Submit Brief
              </LoadingButton>
            </Stack>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default Brief;
