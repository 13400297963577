import React from "react";
import Stack from "@mui/material/Stack";
import useJumboLayoutSidebar from "@jumbo/hooks/useJumboLayoutSidebar";
import Logo from "../../../../shared/Logo";
import useJumboHeaderTheme from "@jumbo/hooks/useJumboHeaderTheme";
import { Typography } from "@mui/material";

const Header = () => {
  const { headerTheme } = useJumboHeaderTheme();

  return (
    <React.Fragment>
      <Logo sx={{ mr: 3 }} mode={headerTheme.type ?? "light"} />

      <Stack
        direction="row"
        alignItems="center"
        spacing={1.25}
        sx={{ ml: "auto" }}
      >
        <Typography sx={{ color: "#fff" }} variant="h1">
          Production Brief
        </Typography>
      </Stack>
    </React.Fragment>
  );
};

export default Header;
