import jwtAxios from "axios";

const authAxios = jwtAxios.create({
  baseURL: "https://et0fkfday1.execute-api.ap-southeast-2.amazonaws.com/live/",
  headers: {
    "Content-Type": "application/json",
  },
  crossdomain: true,
});

authAxios.interceptors.response.use(
  (res) => res,
  (err) => {
    if (err.response && err.response.data.type === "token-invalid") {
      //todo logout the user
    }
    return Promise.reject(err);
  }
);

export const setAuthToken = (token) => {
  if (token) {
    // authAxios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    authAxios.defaults.headers.common["Authorization"] = "" + token;
    localStorage.setItem("token", token);
  } else {
    delete authAxios.defaults.headers.common["Authorization"];
    localStorage.removeItem("token");
  }
};

export const getAuthToken = () => {
  return localStorage.getItem("token");
};

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default authAxios;
