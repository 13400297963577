import React from "react";
import ContactSupportIcon from "@mui/icons-material/ContactSupport";
import QuizOutlinedIcon from "@mui/icons-material/QuizOutlined";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SubscriptionsOutlinedIcon from "@mui/icons-material/SubscriptionsOutlined";
import NewspaperOutlinedIcon from "@mui/icons-material/NewspaperOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import VideoCallOutlinedIcon from "@mui/icons-material/VideoCallOutlined";
import CurrencyExchangeOutlinedIcon from "@mui/icons-material/CurrencyExchangeOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ModeEditOutlinedIcon from "@mui/icons-material/ModeEditOutlined";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import BackupOutlinedIcon from "@mui/icons-material/BackupOutlined";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import EventNoteIcon from "@mui/icons-material/EventNote";
import MyLocationIcon from "@mui/icons-material/MyLocation";
import LoginIcon from "@mui/icons-material/Login";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import LockResetIcon from "@mui/icons-material/LockReset";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import ScreenLockRotationIcon from "@mui/icons-material/ScreenLockRotation";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import RunningWithErrorsOutlinedIcon from "@mui/icons-material/RunningWithErrorsOutlined";
import GraphicEqIcon from "@mui/icons-material/GraphicEq";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WidgetsOutlinedIcon from "@mui/icons-material/WidgetsOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import StreamOutlinedIcon from "@mui/icons-material/StreamOutlined";
import ListIcon from "@mui/icons-material/List";
import ListAltOutlinedIcon from "@mui/icons-material/ListAltOutlined";
import ViewListOutlinedIcon from "@mui/icons-material/ViewListOutlined";
import SupportAgentOutlinedIcon from "@mui/icons-material/SupportAgentOutlined";
import PieChartOutlineOutlinedIcon from "@mui/icons-material/PieChartOutlineOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ContactPageOutlinedIcon from "@mui/icons-material/ContactPageOutlined";
import CallEndIcon from "@mui/icons-material/CallEnd";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import NewspaperIcon from "@mui/icons-material/Newspaper";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import GridViewIcon from "@mui/icons-material/GridView";
import Grid3x3OutlinedIcon from "@mui/icons-material/Grid3x3Outlined";
import SourceOutlinedIcon from "@mui/icons-material/SourceOutlined";
import { SubscriptionsOutlined } from "@mui/icons-material";
import VideoCameraBackOutlinedIcon from "@mui/icons-material/VideoCameraBackOutlined";

const menus = [
  {
    label: "sidebar.menu.welcome",
    type: "section",
    children: [
      {
        uri: "/",
        label: "sidebar.menuItem.dashboard",
        type: "nav-item",
        icon: <HomeOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/create-tvc",
        label: "sidebar.menuItem.createJob",
        type: "nav-item",
        icon: <VideoCameraBackOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/jobs/listAll",
        label: "sidebar.menuItem.listAllJobs",
        type: "nav-item",
        icon: <SubscriptionsOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.content",
    type: "section",
    children: [
      {
        uri: "/news",
        label: "sidebar.menuItem.news",
        type: "nav-item",
        icon: <NewspaperOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/supported",
        label: "Unsure",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
  {
    label: "sidebar.menu.help",
    type: "section",
    children: [
      {
        uri: "/how-to",
        label: "sidebar.menuItem.howto",
        type: "nav-item",
        icon: <DescriptionOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/FAQ",
        label: "sidebar.menuItem.faq",
        type: "nav-item",
        icon: <QuizOutlinedIcon sx={{ fontSize: 20 }} />,
      },
      {
        uri: "/support",
        label: "sidebar.menuItem.contactSupport",
        type: "nav-item",
        icon: <SupportAgentOutlinedIcon sx={{ fontSize: 20 }} />,
      },
    ],
  },
];

export default menus;
