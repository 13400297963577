import baseAxios from "axios";

const axios = baseAxios.create({
  baseURL: "https://as8qyzp57j.execute-api.ap-southeast-2.amazonaws.com/Prod",
  headers: {
    "Content-Type": "application/json",
  },
  crossDomain: true,
});

//todo: define interceptors and other configuration like baseURL, headers etc. here
export default axios;
