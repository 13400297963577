import React, { createContext, useState } from "react";
import {
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";
import Pool from "app/services/auth/AWSUserPool";
import axios from "./config";

const authServices = {};

authServices.getCurrentUser = async () => {
  // console.log("auth check started");
  return await new Promise((resolve, reject) => {
    const user = Pool.getCurrentUser();
    if (user) {
      user.getSession(async (err, session) => {
        if (err) {
          reject();
        } else {
          const attributes = await new Promise((resolve, reject) => {
            user.getUserAttributes((error, attributes) => {
              if (err) {
                reject(err);
              } else {
                // console.log("User Attributes: ", attributes);
                const results = {};

                for (let attribute of attributes) {
                  const { Name, Value } = attribute;
                  results[Name] = Value;
                }

                resolve(results);
              }
            });
          });

          resolve({ user, ...session, ...attributes });
        }
      });
    } else {
      //reject();
      return {
        hasError: true,
        error: "Some error received from the api",
      };
    }
  });
};

export default authServices;
