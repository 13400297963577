import { LAYOUT_CONTAINER_STYLES } from "@jumbo/utils/constants/layout";
import { mainTheme } from "../themes/main/rcdefault";
import { headerTheme } from "../themes/header/rcdefault";
import { sidebarTheme } from "../themes/sidebar/rcdefault";
import { footerTheme } from "../themes/footer/rcdefault";
import LAYOUT_NAMES from "../layouts/layouts";
import { createJumboTheme } from "@jumbo/utils";
import jwtAuthAxios from "../services/auth/jwtAuth";
import authServices from "../services/auth-services";

const config = {
  defaultLayout: LAYOUT_NAMES.VERTICAL_DEFAULT,
  containerStyle: LAYOUT_CONTAINER_STYLES.FLUID,

  theme: createJumboTheme(mainTheme, headerTheme, sidebarTheme, footerTheme),

  /* authSetting: {
    axiosObject: jwtAuthAxios,
    fallbackPath: "/",
    getAuthUserService: authServices.getCurrentUser,
    redirectNotAuthenticatedPath: "/",
  }, */
};

export { config };
